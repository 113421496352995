import thinkingdata from 'thinkingdata-browser'
import { getLanguage } from '@/utils/language'
const language = getLanguage()

console.log('language', language)
const TDCONFIG = {
  prod: {
    appId: '71dbbf97e7c6458ca683aaab7aab3548',
    serverUrl: 'https://ted.tapon.com',
  },
  test: {
    appId: '31925c188447455aa82f8d5467605363',
    serverUrl: 'https://test-ted.tapon.com',
  },
  dev: {
    appId: '83c7ad4d58544409a3a1801d94509fa1',
    serverUrl: 'https://test-ted.tapon.com',
  },
}

let envType = ''

if (process.env.env === 'test') {
  envType = 'test'
} else if (process.env.env === 'production') {
  envType = 'prod'
} else {
  envType = 'dev'
}

const ta = thinkingdata

const config = {
  appId: TDCONFIG[envType].appId,
  serverUrl: TDCONFIG[envType].serverUrl,
  send_method: 'image',
  useAppTrack: true,
  autoTrack: {
    pageShow: false, // 开启页面展示事件，事件名ta_page_show
    pageHide: false, // 开启页面隐藏事件，事件名ta_page_hide
  },
}
console.log('数数接入', config)
ta.init(config)
// 获取channel
const superProperties: any = {}
superProperties.origin = language
superProperties.url = window.location.href
ta.setSuperProperties(superProperties) // 设置公共事件属性

export default ta
